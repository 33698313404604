<template>
    <mercur-card class="fill full-height-layout mx-4 mb-3">
        <grid-header :big-headline="true">
            Turnaround Strategy
            <template slot="actions">
                <mercur-button data-test="turnaroundStrategyAddNewButton" @click="triggerAdd" class="btn btn-raised btn-primary">
                    <span>Add new strategy</span>
                </mercur-button>
            </template>
        </grid-header>
        <mercur-card class="bg-primary" style="min-height: 92px;">
            <pretty-select
                data-test="turnaroundStrategySelector"
                v-if="turnaroundStrategies.length && !loading"
                v-model="selectedStrategy"
                :options="turnaroundStrategies"
                label="turnaroundStrategyName"
                placeholder="Select existing strategy"
                color="white"
            ></pretty-select>
            <mercur-spinner v-else-if="!turnaroundStrategies.length || loading"/>
        </mercur-card>
        <div v-if="Object.keys(selectedStrategy).length">
            <div class="row">
                <div class="col">
                    <p class="fs-16 mt-3">{{ selectedStrategy.turnaroundStrategyDescription }}</p>
                    <p>
                        <mercur-button data-test="turnaroundStrategyEditButton" class="btn btn-outline-blue" @click="triggerEdit" :disabled="editingStrategyTable || loading"><i class="fas fa-pen"></i>Edit details</mercur-button>
                        <mercur-button data-test="turnaroundStrategyDuplicateButton" class="btn btn-outline-blue" @click="triggerDuplicate" :disabled="editingStrategyTable || loading"><i class="fas fa-clone"></i>Duplicate table</mercur-button>
                        <mercur-button data-test="turnaroundStrategyDeleteButton" class="btn btn-raised btn-danger m-0 u-float-right" @click="triggerDelete" :disabled="editingStrategyTable || loading">Delete turnaround strategy</mercur-button>
                    </p>
                    <hr>
                </div>
            </div>
            <div class="row mt-3 justify-content-center align-items-center" v-if="gettingUpsellById">
                <mercur-spinner />
            </div>
            <div class="row mt-3">
                <div class="col d-flex justify-content-end align-items-center" v-if="Object.keys(strategyUpsell).length">
                    <mercur-button class="btn btn-outline-blue mr-1" v-if="editingStrategyTable" @click="triggerUndo" :disabled="loading">
                        <i class="fas fa-undo"></i> Revert changes
                    </mercur-button>
                    <mercur-button data-test="turnaroundStrategyEditTableButton" class="btn btn-raised btn-white mr-1" @click="triggerEditTable" :disabled="editingStrategyExtraStepsTable || loading">{{editingStrategyTable ? 'Cancel' : 'Edit table'}}</mercur-button>
                    <mercur-button data-test="turnaroundStrategyEditTableSaveButton" class="btn btn-raised btn-success" @click="updateStrategyTable" :disabled="!editingStrategyTable || loading">Save changes</mercur-button>
                </div>
            </div>
            <div class="d-flex flex-wrap" v-if="Object.keys(strategyUpsell).length">
                <div class="mr-auto">
                    <table class="margin-table mb-3 mt-3" :class="{'with-labels': !editingStrategyTable}">
                        <thead>
                            <tr>
                                <th colspan="2">Static upsells</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr data-test="turnaroundStrategyTableRow" v-for="(value, key) in selectedStrategy.turnaroundStrategyTable.staticUpsells" :key="key">
                                <td>
                                    <mercur-input data-test="turnaroundStrategyTableInput" class="mb-0" :value="key" v-if="editingStrategyTable" :disabled="true"/>
                                    <template v-else>{{ key }}</template>
                                </td>
                                <td>
                                    <template v-if="editingStrategyTable">
                                        <turnaround-upsell-selector data-test="turnaroundUpsellSelectorTableInput" v-model.number="selectedStrategy.turnaroundStrategyTable.staticUpsells[key]" :turnaround-upsells="strategyUpsell.turnaroundUpsellTable.upsells" />
                                        <span class="error" v-if="$v.selectedStrategy.$dirty && !$v.selectedStrategy.turnaroundStrategyTable.staticUpsells.$each[key].required">Required</span>
                                    </template>
                                    <template v-else>{{ upsellName(value) }}</template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="margin-table mt-3" :class="{'with-labels': !editingStrategyTable}">
                        <thead>
                            <tr>
                                <th class="margin-table__checkbox" v-if="editingStrategyTable"><mercur-checkbox color="purple" v-model="selectAll"></mercur-checkbox></th>
                                <template v-if="selectedRows.length > 0">
                                    <th colspan="4">
                                        <mercur-tooltip>
                                            <template slot="label">Delete selected rows</template>
                                            <mercur-button @click="deleteSelectedRows" class="btn btn-icon">
                                                <i class="fas fa-trash"></i>
                                            </mercur-button>
                                        </mercur-tooltip>
                                    </th>
                                </template>
                                <th v-else colspan="4" style="min-width: 190px;">Differences</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(value, index) in selectedStrategy.turnaroundStrategyTable.differences" :key="'difference-' + index">
                            <td class="margin-table__checkbox" v-if="editingStrategyTable"><mercur-checkbox color="purple" v-model="selectedRows" :value="value.temporaryId"></mercur-checkbox></td>
                            <td>
                                <label for="">Distance</label>
                                <template v-if="editingStrategyTable">
                                    <mercur-input data-test="turnaroundStrategyTableDifferenceInput" class="mb-0" style="width: 120px;" v-model.number="value.turnaroundDifference"/>
                                    <span class="error" v-if="$v.selectedStrategy.$dirty && !$v.selectedStrategy.turnaroundStrategyTable.differences.$each[index].turnaroundDifference.required">Required</span>
                                    <span class="error" v-if="$v.selectedStrategy.$dirty && !$v.selectedStrategy.turnaroundStrategyTable.differences.$each[index].turnaroundDifference.integer">Needs to be integer</span>
                                    <span class="error" v-else-if="$v.selectedStrategy.$dirty && !$v.selectedStrategy.turnaroundStrategyTable.differences.$each[index].turnaroundDifference.unique">Not unique</span>
                                    <span class="error" v-else-if="$v.selectedStrategy.$dirty && !$v.selectedStrategy.turnaroundStrategyTable.differences.$each[index].turnaroundDifference.isGreaterThanStepsSum">Needs to be bigger than sum of steps</span>
                                </template>
                                <template v-else>{{ value.turnaroundDifference }}</template>
                            </td>
                            <td :colspan="getColspan(value)">
                                <label v-if="!editingStrategyTable">Fastest upsell</label>
                                <template v-if="editingStrategyTable">
                                    <turnaround-upsell-selector data-test="turnaroundStrategyTableDifferenceSelectorInput" width="120px" v-model.number="value.fastestUpsell" label="Fastest upsell" :turnaround-upsells="strategyUpsell.turnaroundUpsellTable.upsells" />
                                    <span class="error" v-if="$v.selectedStrategy.$dirty && !$v.selectedStrategy.turnaroundStrategyTable.differences.$each[index].fastestUpsell.required">Required</span>
                                </template>
                                <template v-else>{{ upsellName(value.fastestUpsell) }}</template>
                            </td>
                            <td class="steps" v-if="editingStrategyTable || value.differenceSteps.length" :class="{'with-labels': !editingStrategyTable}">
                                <span class="position-relative d-flex steps__step" v-for="(step, stepIndex) in value.differenceSteps" :key="'step1-' + step.step + stepIndex + value.temporaryId">
                                    <span>
                                        <label>Step</label>
                                        <input type="text" width="100px" v-if="editingStrategyTable" v-model.number="step.step">
                                        <template v-else>{{ step.step }}</template>
                                        <span class="error" v-if="!$v.selectedStrategy.turnaroundStrategyTable.differences.$each[index].differenceSteps.$each[stepIndex].step.minValue">Min is 1!</span>
                                    </span>
                                    <span>
                                        <turnaround-upsell-selector width="120px" v-model.number="step.upsell"  v-if="editingStrategyTable" :turnaround-upsells="strategyUpsell.turnaroundUpsellTable.upsells" />
                                        <template v-else>
                                            <label>Upsell</label>
                                            {{ upsellName(step.upsell) }}
                                        </template>
                                    </span>
                                     <mercur-button v-if="editingStrategyTable"  :key="'button-' + step.step + stepIndex + value.temporaryId" @click="deleteStep(value.temporaryId, stepIndex)" class="btn btn-icon btn-icon-square btn-white step-delete"><i class="fas fa-trash"></i></mercur-button>
                                </span>
                                <span v-if="editingStrategyTable && value.differenceSteps.length < 2 && (value.turnaroundDifference > 2 || (value.turnaroundDifference === 2 && value.differenceSteps.length < 1))">
                                    <mercur-tooltip>
                                        <template slot="label">Add difference step</template>
                                        <mercur-button class="btn btn-icon" @click="addDifferenceStep(value)"><i class="fas fa-plus"></i></mercur-button>
                                    </mercur-tooltip>
                                </span>
                            </td>
                            <td v-if="editingStrategyTable">
                                <mercur-menu>
                                    <mercur-button class="btn btn-icon"><i class="fas fa-ellipsis-v"></i></mercur-button>
                                    <div slot="dropdown">
                                        <mercur-item @click.native="insertRowAbove(index)">Insert row above</mercur-item>
                                        <mercur-item @click.native="insertRowBelow(index)">Insert row below</mercur-item>
                                        <hr class="m-0">
                                        <mercur-item @click.native="deleteRow(value.temporaryId)">Delete row</mercur-item>
                                    </div>
                                </mercur-menu>
                            </td>
                        </tr>
                        <tr v-if="editingStrategyTable">
                            <td class="u-text-center" colspan="100">
                                <mercur-button data-test="turnaroundStrategyEditingTableAddRowButton" class="btn btn-outline-blue font-weight-bold" @click="addRow"><i class="fas fa-plus-square"></i> Add row</mercur-button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <table class="margin-table mb-3 mt-3" :class="{'with-labels': !editingStrategyExtraStepsTable}">
                            <thead>
                                <tr>
                                    <th colspan="1">
                                        <div>Extra Steps</div>
                                    </th>
                                    <th colspan="3">
                                        <mercur-button  class="btn btn-raised btn-white ml-auto" @click="triggerEditTableExtraSteps" :disabled="editButtonExtraStepsTableIsDisabled || loading">{{editingStrategyExtraStepsTable ? 'Cancel' : 'Edit table'}}</mercur-button>
                                        <mercur-button  class="btn btn-raised btn-success ml-3" @click="updateStrategyTable" :disabled="!editingStrategyExtraStepsTable || loading">Save changes</mercur-button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(step, index) in selectedStrategy.turnaroundExtraStepsTable" :key="'extradifference-' + index">
                                <td>
                                        <label >Day(s)</label>
                                        <template v-if="editingStrategyExtraStepsTable">
                                            <mercur-input v-model.number="step.turnaroundDays" class="mb-0" />
                                            <template v-if="$v.selectedStrategy.turnaroundExtraStepsTable.$dirty">
                                                <span class="error" v-if="!$v.selectedStrategy.turnaroundExtraStepsTable.$each[index].turnaroundDays.required">Required</span>
                                                <span class="error" v-else-if="!$v.selectedStrategy.turnaroundExtraStepsTable.$each[index].turnaroundDays.integer">Needs to be integer</span>
                                                <span class="error" v-else-if="!$v.selectedStrategy.turnaroundExtraStepsTable.$each[index].turnaroundDays.unique">Not unique</span>
                                            </template>
                                        </template>
                                        <template v-else>{{ step.turnaroundDays }}</template>
                                </td>
                                <td>
                                        <label>Difference</label>
                                        <template v-if="editingStrategyExtraStepsTable">
                                            <select v-model="step.turnaroundStrategyDifference">
                                                <option v-for="dif in selectedStrategy.turnaroundStrategyTable.differences" :key="'applydifference' + $uuid.v4() + dif.turnaroundDifference" :value="dif.turnaroundDifference">{{ dif.turnaroundDifference }}</option>
                                            </select>
                                            <template v-if="$v.selectedStrategy.turnaroundExtraStepsTable.$dirty">
                                                <p class="error" v-if="!$v.selectedStrategy.turnaroundExtraStepsTable.$each[index].turnaroundStrategyDifference.required">Required</p>
                                            </template>
                                        </template>
                                        <template v-else>{{ step.turnaroundStrategyDifference }}</template>
                                </td>
                                <td>
                                    <mercur-button v-if="step.turnaroundDays && step.turnaroundStrategyDifference !== null" class="btn btn-icon" @click.native="setOpenExtraStepPopUp(step)"><i class="fas fa-info"></i></mercur-button>
                                    <mercur-button v-if="editingStrategyExtraStepsTable" class="btn btn-icon" @click.native="deleteRowExtraSteps(index)"><i class="fas fa-trash"></i></mercur-button>
                                </td>
                            </tr>
                            <tr v-if="editingStrategyExtraStepsTable">
                                <td class="u-text-center" colspan="100">
                                    <mercur-button  class="btn btn-outline-blue font-weight-bold" @click="addRowExtraSteps"><i class="fas fa-plus-square"></i> Add row</mercur-button>
                                </td>
                            </tr>
                            </tbody>
                    </table>
                </div>
                <div class="mt-3 mb-3 mr-3">
                    <turnaround-upsell-table :editing-upsell-table="false" :selected-turnaround-upsell="strategyUpsell">
                        <h4 class="mt-0 mb-0 u-text-center pl-5 pr-5">Turnaround upsell configuration connected to this strategy</h4>
                    </turnaround-upsell-table>
                </div>
            </div>
        </div>

        <mercur-dialog data-test="turnaroundStrategyDeleteModal" :is-open.sync="isDelete">
            <p>Are you sure you want to remove this turnaround strategy?</p>
            <template slot="footer">
                <mercur-button class="btn btn-raised" :disabled="loading" @click="triggerClose">Cancel</mercur-button>
                <mercur-button data-test="turnaroundStrategyDeleteModalConfirmButton"  class="btn btn-raised btn-primary" :disabled="loading" @click="remove">Delete</mercur-button>
            </template>
        </mercur-dialog>

        <mercur-dialog data-test="turnaroundStrategyAddNewModal" :is-open.sync="isOpen">
            <template v-if="isDuplicate">
                <h3 class="mt-0 font-weight-normal">Duplicate turnaround strategy</h3>
                <p>You can change name and description, but turnaround strategy table will be cloned</p>
            </template>
            <h2 class="mt-0 font-weight-normal" v-else>{{ draft ? 'Edit turnaround strategy' : 'Add new turnaround strategy'}}</h2>
            <mercur-input data-test="turnaroundStrategyModalNameInput"  v-model.trim="form.turnaroundStrategyName" :class="{'form-invalid': $v.form.turnaroundStrategyName.$error}">
                Price margin table name
                <template slot="note">
                    <span class="form-error" v-if="!$v.form.turnaroundStrategyName.required">Turnaround strategy name is required</span>
                    <span class="form-error" v-else-if="!$v.form.turnaroundStrategyName.unique">Turnaround strategy name needs to be unique</span>
                </template>
            </mercur-input>
            <mercur-input data-test="turnaroundStrategyModalDescriptionInput" v-model.trim="form.turnaroundStrategyDescription">
                Turnaround strategy description
            </mercur-input>
            <div>
                <pretty-select
                    data-test="turnaroundUpsellSelectorModal"
                    v-if="turnaroundUpsells.length"
                    v-model="form.turnaroundUpsellId"
                    :options="turnaroundUpsells"
                    :reduce="upsell => upsell.turnaroundUpsellId"
                    :disabled="isEdit"
                    label="turnaroundUpsellName"
                    placeholder="Select turnaround upsell"
                ></pretty-select>
                <alert v-else type="danger">Turnaround upsells are missing!</alert>
                <span class="error pretty-select-error" v-if="$v.form.turnaroundUpsellId.$error">
                    Turnaround upsell is required
            </span>
            </div>
            <template slot="footer">
                <mercur-button class="btn btn-raised" :disabled="loading" @click="triggerClose">Cancel</mercur-button>
                <mercur-button data-test="turnaroundStrategyModalDuplicateButton" v-if="isDuplicate" @click="submit" class="btn btn-raised btn-primary" :disabled="loading">Duplicate</mercur-button>
                <mercur-button data-test="turnaroundStrategyModalSaveButton" v-else class="btn btn-raised btn-primary" @click="submit" :disabled="loading">
                    {{ draft ? 'Update turnaround strategy' : 'Add new turnaround strategy'}}
                </mercur-button>
            </template>
        </mercur-dialog>

        <mercur-dialog :is-open.sync="extraStepPopUp.open">
            <div v-if="extraStepPopUp.info">
                <div slot="header"><h3 class="m-0">Turnaround Visualisation</h3></div>
                <table class="w-full pl-3 pr-3 mt-3" >
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Fastest</th>
                            <th v-for="(step, index) in getDistanceStepsNumber()" :key="'headerStep'+ index">Step {{step.step}}</th>
                            <th>Cheapest</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-center">
                                <strong>Days</strong>
                            </td>
                            <td class="text-center">
                                {{extraStepPopUp.info.turnaroundDays}}
                            </td>
                            <td class="text-center" v-for="(step, index) in getDistanceStepsNumber()" :key="'headerStep'+ index">
                                {{getNumDaysByDistanceStep(index)}}
                            </td>
                            <td class="text-center">
                               {{extraStepPopUp.info.turnaroundDays + extraStepPopUp.info.turnaroundStrategyDifference}}
                            </td>
                        </tr>
                        <tr>
                            <td class="text-center">
                                <strong>Upsell</strong>
                            </td>
                            <td class="text-center">
                                {{getUpSellFromValue(extraStepPopUp.info.turnaroundStrategyDifference)}}
                            </td>
                            <td class="text-center" v-for="(step, index) in getDistanceStepsNumber()" :key="'headerStep'+ index">
                                {{upsellName(step.upsell)}}
                            </td>
                            <td>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </mercur-dialog>
    </mercur-card>
</template>

<script>
import CONFIG from '@root/config'
import GridHeader from '@/components/GridHeader'
import PrettySelect from '../../components/utils/PrettySelect'
import TurnaroundUpsellTable from '../../components/pricing/TurnaroundUpsellTable'
import { required, integer, minValue } from 'vuelidate/lib/validators'
import { reverseStripProperties } from '@/components/utils/Utils'
import TurnaroundUpsellSelector from '../../components/pricing/TurnaroundUpsellSelector'
import Alert from '../../components/Alert'

const defaultForm = {
    turnaroundStrategyName: '',
    turnaroundStrategyDescription: '',
    turnaroundUpsellId: null,
    turnaroundStrategyTable: {
        staticUpsells: {},
        differences: [],
    },
    turnaroundExtraStepsTable: [],
}

const emptyDifference = {
    fastestUpsell: null,
    differenceSteps: [],
    turnaroundDifference: '',
}

const emptyExtraStep = {
    turnaroundDays: '',
    turnaroundStrategyDifference: '',
}

export default {
    name: 'TurnaroundStrategy',
    components: { GridHeader, PrettySelect, TurnaroundUpsellTable, TurnaroundUpsellSelector, Alert },
    data () {
        return {
            draft: null,
            isOpen: false,
            isEdit: false,
            isDuplicate: true,
            form: defaultForm,
            loading: false,
            turnaroundStrategies: [],
            turnaroundUpsells: [],
            selectedStrategy: {},
            strategyUpsell: {},
            turnaroundExtraStepsTable: [],
            editingStrategyTable: false,
            editingStrategyExtraStepsTable: false,
            selectedDraft: null,
            selectAll: false,
            selectedRows: [],
            gettingUpsellById: false,
            isDelete: false,
            errorDeletingDistancePresentInExtraSteps: false,
            extraStepPopUp: {
                open: false,
                info: null,
            },
        }
    },
    validations: {
        form: {
            turnaroundStrategyName: {
                required,
                unique: function (value, vm) {
                    return this.checkIfUnique(vm)
                },
            },
            turnaroundUpsellId: {
                required,
            },
        },
        selectedStrategy: {
            turnaroundStrategyTable: {
                staticUpsells: {
                    $each: {
                        required,
                    },
                },
                differences: {
                    $each: {
                        fastestUpsell: {
                            required,
                        },
                        turnaroundDifference: {
                            required,
                            integer,
                            minValue: minValue(0),
                            unique: function (value, vm) {
                                const previousValues = this.selectedStrategy.turnaroundStrategyTable.differences.map(e => e.turnaroundDifference)
                                previousValues.splice(previousValues.findIndex(e => e === vm.turnaroundDifference), 1)
                                return !previousValues.includes(vm.turnaroundDifference)
                            },
                            isGreaterThanStepsSum: function (value, vm) {
                                if (!vm.differenceSteps || !vm.differenceSteps.length) {
                                    return true
                                }
                                return value > vm.differenceSteps.reduce((a, b) => a + b.step, 0)
                            },
                        },
                        differenceSteps: {
                            $each: {
                                step: {
                                    minValue: minValue(1),
                                },
                            },
                        },
                    },
                },
            },
            turnaroundExtraStepsTable: {
                $each: {
                    turnaroundDays: {
                        required,
                        integer,
                        minValue: minValue(1),
                        unique: function (value, vm) {
                            const previousValues = this.selectedStrategy.turnaroundExtraStepsTable.map(e => e.turnaroundDays)
                            const i = previousValues.findIndex(e => e === vm.turnaroundDays)
                            previousValues.splice(i, 1)
                            return !previousValues.includes(vm.turnaroundDays)
                        },
                    },
                    turnaroundStrategyDifference: {
                        required,
                    },
                },
            },
        },
    },
    watch: {
        selectedStrategy: {
            handler (value) {
                if (!value) {
                    return
                }
                this.$router.push({
                    name: 'TurnaroundStrategy',
                    params: {
                        turnaroundStrategyId: value.turnaroundStrategyId,
                    },
                })

                if (value.turnaroundUpsellId !== this.strategyUpsell.turnaroundUpsellId) {
                    this.getTurnaroundUpsellById(value.turnaroundUpsellId)
                }
            },
            deep: true,
        },
        selectAll (value) {
            if (value) {
                this.$set(this, 'selectedRows', this.selectedStrategy.turnaroundStrategyTable.differences.map(e => e.temporaryId))
            } else {
                this.$set(this, 'selectedRows', [])
            }
        },
        selectedRows (value) {
            if (value.length === this.selectedStrategy.turnaroundStrategyTable.differences.length) {
                this.$set(this, 'selectAll', true)
            }

            if (!value.length) {
                this.$set(this, 'selectAll', false)
            }
        },
    },
    computed: {
        editButtonExtraStepsTableIsDisabled () {
            return this.selectedStrategy.turnaroundStrategyTable.differences.length < 1 || this.editingStrategyTable
        },
    },
    methods: {
        triggerAdd () {
            this.draft = null
            this.isOpen = true
            this.isDuplicate = false
        },
        triggerEdit () {
            this.draft = JSON.parse(JSON.stringify(this.selectedStrategy))
            this.form = this.draft
            this.isOpen = true
            this.isEdit = true
            this.isDuplicate = false
        },
        triggerClose () {
            this.draft = null
            this.isOpen = false
            this.isDuplicate = false
            this.isEdit = false
            this.form = defaultForm
            this.isDelete = false
        },
        triggerDuplicate () {
            this.isOpen = true
            this.isDuplicate = true
            this.form = JSON.parse(JSON.stringify(this.selectedStrategy))
        },
        triggerDelete () {
            this.isDelete = true
        },
        remove () {
            let url = CONFIG.API.ROUTES.PRICING.TURNAROUND_STRATEGY.DELETE.replace('{turnaroundStrategyId}', this.selectedStrategy.turnaroundStrategyId)
            this.loading = true
            this.$api.delete(url).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Turnaround strategy was deleted',
                    type: 'success',
                })
                const index = this.turnaroundStrategies.findIndex(e => e.turnaroundStrategyId === data.data.turnaroundStrategyId)
                this.triggerClose()
                this.$delete(this.turnaroundStrategies, index)
                this.$set(this, 'selectedStrategy', {})
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: (err.data && err.data.exceptions && err.data.exceptions[0]) ? err.data.exceptions[0] : 'Something went wrong',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        submit () {
            this.$v.$touch()
            if (this.$v.$invalid) {
                return
            }

            if (this.isEdit) {
                this.updateTurnaroundStrategy()
                return
            }

            const url = CONFIG.API.ROUTES.PRICING.TURNAROUND_STRATEGY.CREATE
            this.loading = true
            let payload = this.form
            if (this.form.turnaroundStrategyId) {
                payload = reverseStripProperties(payload, ['turnaroundStrategyName', 'turnaroundStrategyTable', 'turnaroundStrategyDescription', 'turnaroundUpsellId', 'turnaroundExtraStepsTable'])
            }

            this.$api.post(url, payload).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Turnaround strategy was added',
                    type: 'success',
                })
                this.$set(this, 'turnaroundStrategies', [
                    ...this.turnaroundStrategies,
                    data.data,
                ])
                this.triggerClose()
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        getColspan (value) {
            if (this.editingStrategyTable) {
                return '1'
            }

            return value.differenceSteps.length > 0 ? '1' : '2'
        },
        listTurnaroundUpsells () {
            const url = CONFIG.API.ROUTES.PRICING.TURNAROUND_UPSELL.LIST
            this.loading = true
            this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                this.$set(this, 'turnaroundUpsells', data.data.items)
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong fetching data',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        listTurnaroundStrategies () {
            const url = CONFIG.API.ROUTES.PRICING.TURNAROUND_STRATEGY.LIST
            this.loading = true
            this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                this.$set(this, 'turnaroundStrategies', data.data.items)
                if (this.$route.params.turnaroundStrategyId) {
                    this.$set(this, 'selectedStrategy', this.turnaroundStrategies.find(e => e.turnaroundStrategyId === this.$route.params.turnaroundStrategyId))
                }
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong fetching data',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        async updateTurnaroundStrategy (data) {
            const url = CONFIG.API.ROUTES.PRICING.TURNAROUND_STRATEGY.UPDATE
            this.loading = true
            const payload = data || this.form
            await this.$api.post(url, payload).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Turnaround strategy was updated',
                    type: 'success',
                })
                const changedTableIndex = this.turnaroundStrategies.findIndex(e => e.turnaroundStrategyId === data.data.turnaroundStrategyId)
                const newArray = this.turnaroundStrategies
                newArray[changedTableIndex] = data.data
                this.$set(this, 'turnaroundStrategies', newArray)
                this.$set(this, 'selectedStrategy', data.data)
                this.triggerClose()
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        checkIfUnique (vm) {
            let strategies = JSON.parse(JSON.stringify(this.turnaroundStrategies))
            if (this.isEdit) {
                strategies.splice(strategies.findIndex(e => e.turnaroundStrategyId === this.selectedStrategy.turnaroundStrategyId), 1)
            }
            const previousNames = strategies.map(e => e.turnaroundStrategyName)
            return !previousNames.includes(vm.turnaroundStrategyName)
        },
        getTurnaroundUpsellById (id) {
            const url = CONFIG.API.ROUTES.PRICING.TURNAROUND_UPSELL.GET.replace('{turnaroundUpsellId}', id)
            this.gettingUpsellById = true
            this.$api.get(url).then(({ data }) => {
                this.$set(this, 'strategyUpsell', data.data)
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong fetching turnaround upsell',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.gettingUpsellById = false
            })
        },

        // TABLE METHODS
        addRowExtraSteps () {
            const payload = {
                ...JSON.parse(JSON.stringify(emptyExtraStep)),
                temporaryId: this.$uuid.v4(),
            }
            this.selectedStrategy.turnaroundExtraStepsTable.push(payload)
        },
        deleteRowExtraSteps (index) {
            this.selectedStrategy.turnaroundExtraStepsTable.splice(index, 1)
        },
        triggerEditTableExtraSteps () {
            if (this.editingStrategyExtraStepsTable) {
                this.editingStrategyExtraStepsTable = false
                this.$set(this.selectedStrategy, 'turnaroundExtraStepsTable', JSON.parse(JSON.stringify(this.selectedDraft)))
                this.selectedDraft = null
                return
            }

            this.editingStrategyExtraStepsTable = true
            if (!this.selectedStrategy.turnaroundExtraStepsTable) {
                this.selectedStrategy.turnaroundExtraStepsTable = []
            }
            this.selectedDraft = JSON.parse(JSON.stringify(this.selectedStrategy.turnaroundExtraStepsTable))
            this.selectedStrategy.turnaroundExtraStepsTable = this.selectedStrategy.turnaroundExtraStepsTable.map((e) => {
                return { ...e, temporaryId: this.$uuid.v4() }
            })
        },
        getUpSellFromValue (value) {
            const i = this.selectedStrategy.turnaroundStrategyTable.differences.findIndex(item => item.turnaroundDifference === value)
            return this.upsellName(this.selectedStrategy.turnaroundStrategyTable.differences[i].fastestUpsell)
        },
        setOpenExtraStepPopUp (value) {
            this.extraStepPopUp.info = value
            this.extraStepPopUp.open = true
        },
        getDistanceStepsNumber () {
            const i = this.selectedStrategy.turnaroundStrategyTable.differences.findIndex(item => item.turnaroundDifference === this.extraStepPopUp.info.turnaroundStrategyDifference)
            if (!this.selectedStrategy.turnaroundStrategyTable.differences[i]) {
                return
            }
            return this.selectedStrategy.turnaroundStrategyTable.differences[i].differenceSteps
        },
        getNumDaysByDistanceStep (indexStopSearchSteps) {
            const i = this.selectedStrategy.turnaroundStrategyTable.differences.findIndex(item => item.turnaroundDifference === this.extraStepPopUp.info.turnaroundStrategyDifference)
            if (!this.selectedStrategy.turnaroundStrategyTable.differences[i]) {
                return
            }
            let numdays = this.extraStepPopUp.info.turnaroundDays
            this.selectedStrategy.turnaroundStrategyTable.differences[i].differenceSteps.forEach((step, index) => {
                if (index <= indexStopSearchSteps) {
                    numdays += step.step
                }
            })
            return numdays
        },
        addRow () {
            const payload = {
                ...JSON.parse(JSON.stringify(emptyDifference)),
                temporaryId: this.$uuid.v4(),
            }
            this.selectedStrategy.turnaroundStrategyTable.differences.push(payload)
        },
        insertRowAbove (index) {
            const payload = {
                ...JSON.parse(JSON.stringify(emptyDifference)),
                temporaryId: this.$uuid.v4(),
            }
            this.selectedStrategy.turnaroundStrategyTable.differences.splice(index, 0, payload)
        },
        insertRowBelow (index) {
            const payload = {
                ...JSON.parse(JSON.stringify(emptyDifference)),
                temporaryId: this.$uuid.v4(),
            }
            this.selectedStrategy.turnaroundStrategyTable.differences.splice(index + 1, 0, payload)
        },
        deleteRow (temporaryId, isMultiple = false) {
            this.errorDeletingDistancePresentInExtraSteps = false
            const index = this.selectedStrategy.turnaroundStrategyTable.differences.findIndex(e => e.temporaryId === temporaryId)
            const item = this.selectedStrategy.turnaroundStrategyTable.differences[temporaryId] || this.selectedStrategy.turnaroundStrategyTable.differences[index]

            if (item) {
                const found = this.selectedStrategy.turnaroundExtraStepsTable.find(e => e.turnaroundStrategyDifference === item.turnaroundDifference)
                if (found) {
                    this.$root.$emit('notification:global', {
                        type: 'error',
                        message: `Cannot delete Distance ${found.turnaroundStrategyDifference}, because it is present in the "Extra Steps" table`,
                    })
                    this.errorDeletingDistancePresentInExtraSteps = true
                    return
                }
            }
            this.selectedStrategy.turnaroundStrategyTable.differences.splice(index, 1)
            if (this.selectedRows.includes(temporaryId) && !isMultiple) {
                const selectedIndex = this.selectedRows.findIndex(e => e === temporaryId)
                this.selectedRows.splice(selectedIndex, 1)
            }
        },
        deleteSelectedRows () {
            this.errorDeletingDistancePresentInExtraSteps = false
            this.selectedRows.forEach((temporaryId) => {
                this.deleteRow(temporaryId, true)
            })
            this.$set(this, 'selectedRows', [])
        },
        deleteStep (temporaryId, step) {
            const index = this.selectedStrategy.turnaroundStrategyTable.differences.findIndex(e => e.temporaryId === temporaryId)
            this.$delete(this.selectedStrategy.turnaroundStrategyTable.differences[index].differenceSteps, step)
        },
        addDifferenceStep (value) {
            const index = this.selectedStrategy.turnaroundStrategyTable.differences.findIndex(e => e.temporaryId === value.temporaryId)
            if (this.selectedStrategy.turnaroundStrategyTable.differences[index].differenceSteps.length >= 2) {
                this.$root.$emit('notification:global', {
                    message: `Maximum amount of difference steps is two`,
                    type: 'error',
                })
                return
            }
            this.selectedStrategy.turnaroundStrategyTable.differences[index].differenceSteps.push({
                step: '',
                upsell: '',
            })
        },
        updateStrategyTable () {
            if (!this.editingStrategyExtraStepsTable) {
                this.$v.selectedStrategy.$touch()
                if (this.$v.selectedStrategy.turnaroundStrategyTable.$invalid) {
                    this.$root.$emit('notification:global', {
                        message: `Your table configuration is not valid`,
                        type: 'error',
                    })
                    return
                }
            } else {
                this.$v.selectedStrategy.turnaroundExtraStepsTable.$touch()
                if (this.$v.selectedStrategy.turnaroundExtraStepsTable.$invalid) {
                    this.$root.$emit('notification:global', {
                        message: `Your table extra steps configuration is not valid`,
                        type: 'error',
                    })
                    return
                }
            }

            this.updateTurnaroundStrategy(this.selectedStrategy).then(() => {
                this.editingStrategyTable = false
                this.editingStrategyExtraStepsTable = false
                this.selectedDraft = null
            })
        },
        triggerEditTable () {
            if (this.editingStrategyTable) {
                this.editingStrategyTable = false
                this.triggerUndo()
                this.selectedDraft = null
                return
            }

            this.editingStrategyTable = true
            this.selectedDraft = JSON.parse(JSON.stringify(this.selectedStrategy.turnaroundStrategyTable))
            this.addTemporaryIdsToDifferences()
        },
        addTemporaryIdsToDifferences () {
            this.selectedStrategy.turnaroundStrategyTable.differences = this.selectedStrategy.turnaroundStrategyTable.differences.map((e) => {
                return { ...e, temporaryId: this.$uuid.v4() }
            })
        },
        triggerUndo () {
            this.$set(this.selectedStrategy, 'turnaroundStrategyTable', JSON.parse(JSON.stringify(this.selectedDraft)))
        },
        upsellName (value) {
            if (!this.strategyUpsell.turnaroundUpsellTable.upsells) {
                return null
            }

            const upsell = this.strategyUpsell.turnaroundUpsellTable.upsells.find(e => parseInt(e.number) === value)
            return upsell ? upsell.name : ''
        },
    },
    created () {
        this.listTurnaroundUpsells()
        this.listTurnaroundStrategies()
    },
}
</script>

<style lang="scss" scoped>
    .steps {
        display: flex;
        justify-content: left;

        &__step {
            margin: 0 5px;
        }

        input, /deep/ select {
            margin-right: 2px;
            margin-left: 2px;
        }

        span {
            position: relative;

            /deep/ label {
                top: -7px;
                left: 9px;
            }
        }
    }

    .with-labels {
        label {
            top: -14px !important;
            left: 11px !important;
            font-size: 9px;
            background-color: transparent;
            position: relative;
        }
    }

    .step-delete {
        z-index: 3;
        border: 1px solid #979797;
        border-radius: 4px;

        /deep/ i {
            font-size: 13px !important;
            color: black;
        }
    }

    .w-full {
         width: 100%;
    }

</style>
