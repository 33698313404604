<template>
    <div data-test="turnaroundUpsellSelectorWrapper">
        <label data-test="turnaroundUpsellSelectorLabel" for="upsell">{{ label }}</label>
        <select v-model="localValue" name="upsell" :style="{'width': width}">
            <option v-for="upsell in turnaroundUpsells" :key="upsell.number" :value="upsell.number">{{ upsell.name }}</option>
        </select>
    </div>
</template>

<script>
export default {
    name: 'TurnaroundUpsellSelector',
    props: {
        turnaroundUpsells: {
            required: true,
            default: [],
        },
        value: {},
        label: {
            default: 'Upsell',
        },
        width: {
            default: 'inherit',
        },
    },
    computed: {
        localValue: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            },
        },
    },
}
</script>
